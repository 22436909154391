import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Link to="/forms" mdxType="Link">Forms</Link>
    <h1 {...{
      "id": "essential-form"
    }}>{`Essential Form`}</h1>
    <h2 {...{
      "id": "sequence"
    }}>{`Sequence`}</h2>
    <ol>
      <li parentName="ol">{`Opening`}</li>
      <li parentName="ol">{`Grasp the Bird’s Tail`}</li>
      <li parentName="ol">{`Part the Wild Horse’s Mane (Left)`}</li>
      <li parentName="ol">{`Fair Lady Works Shuttles (Right and Left)`}</li>
      <li parentName="ol">{`Brush Knee and Push`}</li>
      <li parentName="ol">{`Golden Pheasant Stands on One Leg (Left and Right)`}</li>
      <li parentName="ol">{`Repel Monkey (Left and Right)`}</li>
      <li parentName="ol">{`High Pat on Horse with Thrusting Palm`}</li>
      <li parentName="ol">{`Right Separation Kick`}</li>
      <li parentName="ol">{`Left Heel Kick`}</li>
      <li parentName="ol">{`Box Ears`}</li>
      <li parentName="ol">{`Step Back, Needle at the Bottom of the Sea`}</li>
      <li parentName="ol">{`Wave Hands Like Clouds (Left and Right)`}</li>
      <li parentName="ol">{`Single Whip / Low Form`}</li>
      <li parentName="ol">{`Step Up to Seven Stars`}</li>
      <li parentName="ol">{`Step Back and Ride the Tiger`}</li>
      <li parentName="ol">{`Turn Body and Kick Over Lotus`}</li>
      <li parentName="ol">{`Bend the Bow, Shoot the Tiger`}</li>
      <li parentName="ol">{`Step Forward, Parry, Block, and Punch`}</li>
      <li parentName="ol">{`Apparent Close-Up`}</li>
      <li parentName="ol">{`Cross Arms`}</li>
      <li parentName="ol">{`Closing`}</li>
    </ol>
    <h2 {...{
      "id": "video"
    }}>{`Video`}</h2>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/ESmo4-vKPsg"
      }}><iframe parentName="a" {...{
          "width": 560,
          "height": 315,
          "src": "https://www.youtube-nocookie.com/embed/ESmo4-vKPsg?rel=0",
          "frameBorder": "0",
          "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          "allowFullScreen": true
        }}></iframe></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      